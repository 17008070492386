import React from 'react';
import { Link } from 'react-router-dom';
import APP_STORAGE from '../../../Storage';
import { useNavigate } from 'react-router-dom';

function Header(props) {
    let navigate = useNavigate();

    const user = {
        name: APP_STORAGE.getName(),
        email: APP_STORAGE.getEmail(),
    }

    function handleSignOut(e) {
        e.preventDefault();

        APP_STORAGE.clearLocalStorage();

        navigate('/login');

        window.location.reload();
    }

    return (
        <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex">
                    <div className="navbar-brand-box">
                        <a href="#" className="logo logo-dark">
                            <span className="logo-sm">
                                <img src="/assets/images/logo-sm.svg" alt="" height="24" />
                            </span>
                            <span className="logo-lg">
                                <img src="/assets/images/logo-smartek.jpeg" alt="" height="36"/> 
                            </span>
                        </a>
                        <a href="#" className="logo logo-light">
                            <span className="logo-sm">
                                <img src="/assets/images/logo-sm.svg" alt="" height="24"/>
                            </span>
                            <span className="logo-lg">
                                <img src="/assets/images/logo-smartek.jpeg" alt="" height="36"/> 
                            </span>
                        </a>
                    </div>
                    <button type="button" className="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn">
                        <i className="fa fa-fw fa-bars"></i>
                    </button>
                </div>

                <div className="d-flex">
                    <div className="dropdown d-inline-block">
                        <button type="button" className="btn header-item bg-soft-light border-start border-end" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img className="rounded-circle header-profile-user" src="/assets/images/users/avatar-1.jpg"
                                alt="Header Avatar"/>
                            <span className="d-none d-xl-inline-block ms-1 fw-medium">{ user.name }</span>
                            <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">
                            {/* <Link to="/profile" className="dropdown-item"><i className="mdi mdi-lock font-size-16 align-middle me-1"></i> Profile</Link>
                            <div className="dropdown-divider"></div> */}
                            <a className="dropdown-item" href="#" onClick={ handleSignOut }><i className="mdi mdi-logout font-size-16 align-middle me-1"></i> Logout</a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;