import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import FormInput from '../../../Components/FormInput';
import PageTabHeader from '../../../Components/Page/PageTabHeader';
import PageTitle from '../../../Components/Page/PageTitle';
import ModelReferensiKomponenGaji from '../../../Models/ReferensiPayroll/komponengaji';
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../../Components/Element/ElementPreloaderForm';
import APP_HELPERS_COMMON from '../../../Helpers/Common';

function Edit() {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
        let urlAction       = useParams();
        let urlID           = urlAction.id;
    /* ----------
    Page Vars
    ------------------ */
        const dataID = APP_HELPERS_COMMON.dcrypt(urlID);
        const [pageTitle, setPageTitle] = useState("Ubah Komponen Gaji");
        const [pageID, setPageID] = useState(26);
        const [tabs, setTabs] = useState([]);
        const [activeTab, setActiveTab] = useState('');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        const defaultOption = { value: '0', label: ' - Pilih -' };
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -----------------
        const [inputKodeKomponen, setInputKodeKomponen] = useState('');
        const [inputNamaKomponen, setInputNamaKomponen] = useState('');
        const [inputKategoriKomponenId, setInputKategoriKomponenId] = useState('');
        const [inputStatusTHP, setInputStatusTHP] = useState(defaultOption);
        const [inputStatusProrate, setInputStatusProrate] = useState(defaultOption);
        const [inputKategoriKomponen, setInputKategoriKomponen] = useState(defaultOption);
        const [inputJenisKomponen, setInputJenisKomponen] = useState(defaultOption);
        const [inputSifatKomponen, setInputSifatKomponen] = useState(defaultOption);
        const [inputKeterangan, setInputKeterangan] = useState('');
        const [inputNoUrut, setInputNoUrut] = useState(0);
        const [inputStatus, setInputStatus] = useState(defaultOption);
        // -----------------
        const [dataStatus, setDataStatus] = useState([defaultOption]);
        const [dataYaTidak, setDataYaTidak] = useState([defaultOption]);
        const [dataKategori, setDataKategori] = useState([defaultOption]);
        const [dataJenis, setJenis] = useState([defaultOption]);
        const [dataSifat, setDataSifat] = useState([defaultOption]);
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let res             = await ModelReferensiKomponenGaji.get(dataID);

            setInputKodeKomponen(res.data.kode_komponen);
            setInputNamaKomponen(res.data.nama_komponen);
            setInputStatus({ value: res.data.status, label: res.data.keterangan_status });
            setInputStatusTHP({ value: res.data.status_thp, label: res.data.ket_status_thp });
            setInputStatusProrate({ value: res.data.status_prorate, label: res.data.ket_status_prorate });
            setInputKategoriKomponen({ value: res.data.kategori_komponen, label: res.data.kategori_komponen });
            setInputJenisKomponen({ value: res.data.jenis_komponen, label: res.data.jenis_komponen });
            setInputSifatKomponen({ value: res.data.sifat_komponen, label: res.data.sifat_komponen });
            setInputKeterangan(res.data.deskripsi_komponen);
            setInputNoUrut(res.data.no_urut);
            
            dataStatus.push(...res.collections.status);
            dataYaTidak.push(...res.collections.ya_tidak);
            dataKategori.push(...res.collections.kategori);
            dataJenis.push(...res.collections.jenis);
            dataSifat.push(...res.collections.sifat);

            setIsLoading(false);

            return () => {
                
            };
        }, []);
    /* ----------
    Component Render
    ------------------ */
        const renderTabs = () => {
            if(tabs.length > 0) {
                return <PageTabHeader tabs={tabs} active={activeTab} handleActiveTab={(data) => handleActiveTab(data)} />
            }
        }
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }

        const handleSubmit = async (e) => {
            e.preventDefault();
            setIsLoadingSubmit(true);
            
            const formSubmit = {
                id: dataID,
                kode_komponen: inputKodeKomponen,
                nama_komponen: inputNamaKomponen,
                status_thp: inputStatusTHP.value,
                status: inputStatus.value,
                status_prorate: inputStatusProrate.value,
                kategori_komponen: inputKategoriKomponen.value,
                jenis_komponen: inputJenisKomponen.value,
                sifat_komponen: inputSifatKomponen.value,
                deskripsi_komponen: inputKeterangan,
                no_urut: inputNoUrut,
            }

            let res = await ModelReferensiKomponenGaji.update(formSubmit);

            if(res.status === '100') {
                navigate("/komponengaji/index/" + APP_HELPERS_COMMON.crypt(pageID.toString()));
                // setAlertMessage(res.message);
                // setAlertOpen(true);
                // setAlertStyle("success");

                // setTimeout(() => {
                //     navigate("/komponengaji/index/" + APP_HELPERS_COMMON.crypt(pageID.toString()));
                // }, 2000);
            } else {
                setIsLoadingSubmit(false);
                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("error");
            }
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{pageTitle}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            {
                                                (isLoading) ? 
                                                    <ElementPreloaderForm />
                                                :
                                                    <>
                                                        <div className="row">
                                                        <FormInput type="text" label="Kode Komponen" size="2" mandatory={true} readonly={true} val={inputKodeKomponen} onChangeValue={(event) => setInputKodeKomponen(event.target.value)}/>
                                                            <FormInput type="text" label="Nama Komponen" size="2" mandatory={true} readonly={true} val={inputNamaKomponen} onChangeValue={(event) => setInputNamaKomponen(event.target.value)}/>
                                                            <FormInput type="select" label="Status THP" size="2" mandatory={true} readonly="readonly" val={inputStatusTHP} option={dataYaTidak} defaultSelect={inputStatusTHP} onChangeValue={(val) => setInputStatusTHP(val)}/>
                                                            <FormInput type="select" label="Status Prorate" size="2" mandatory={true} readonly="readonly" val={inputStatusProrate} option={dataYaTidak} defaultSelect={inputStatusProrate} onChangeValue={(val) => setInputStatusProrate(val)}/>
                                                            <FormInput type="select" label="Kategori Komponen" size="2" mandatory={true} readonly="readonly" val={inputKategoriKomponen} option={dataKategori} defaultSelect={inputKategoriKomponen} onChangeValue={(val) => setInputKategoriKomponen(val)}/>
                                                            <FormInput type="select" label="Jenis Komponen" size="2" mandatory={true} readonly="readonly" val={inputJenisKomponen} option={dataJenis} defaultSelect={inputJenisKomponen} onChangeValue={(val) => setInputJenisKomponen(val)}/>
                                                            <FormInput type="select" label="Sifat Komponen" size="2" mandatory={true} readonly="readonly" val={inputSifatKomponen} option={dataSifat} defaultSelect={inputSifatKomponen} onChangeValue={(val) => setInputSifatKomponen(val)}/>
                                                            <FormInput type="text" label="Keterangan" size="2" mandatory={true} readonly="readonly" val={inputKeterangan} onChangeValue={(event) => setInputKeterangan(event.target.value)}/>
                                                            <FormInput type="text" label="No Urut" size="2" mandatory={true} val={inputNoUrut} onChangeValue={(event) => setInputNoUrut(event.target.value)}/>
                                                            <FormInput type="select" label="Status" size="2" mandatory={true} val={inputStatus} option={dataStatus} defaultSelect={inputStatus} onChangeValue={(val) => setInputStatus(val)}/>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12" style={{textAlign: 'left'}}>
                                                                <FormInput type="submit" label="Update" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Edit;