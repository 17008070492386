import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import PageTitle from '../../../Components/Page/PageTitle';
import CellAction from '../../../Components/DatatTableGrid/CellAction';
import PageActionDirect from '../../../Components/Page/PageActionDirect';
import APP_STORAGE from '../../../Storage';
import PageTable from '../../../Components/PageTable';
import ModelReferensiDepartemen from '../../../Models/Referensi/departemen';
import CommonEndpoint from '../../../Models/common';
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../../Components/Element/ElementPreloaderForm';
import FormInput from '../../../Components/FormInput';
import PageTabHeader from '../../../Components/Page/PageTabHeader';
import APP_HELPERS_COMMON from '../../../Helpers/Common';


function Edit(props) {
    /* ----------
    URL Params
    ------------------ */
    let navigate        = useNavigate();
    let urlAction       = useParams();
    let urlID           = urlAction.id;
        /* ----------
        Page Vars
        ------------------ */
        const dataID = APP_HELPERS_COMMON.dcrypt(urlID);
        const [pageTitle, setPageTitle] = useState("Edit Departemen");
        const [pageID, setPageID] = useState(50);
        const [tabs, setTabs] = useState([]);
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -----------------
        const [inputNamaDepartemen, setInputNamaDepartemen] = useState('');
        const [inputStatus, setInputStatus] = useState(0);
        // -----------------
        const defaultOption = { value: '0', label: ' - Pilih -' };
        const [dataStatus, setDataStatus] = useState([defaultOption]);
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let data = await ModelReferensiDepartemen.get(dataID);
            let reSelectStatus = await ModelReferensiDepartemen.getSelectStatus();
            // console.log('data', reSelectStatus, data);
            setInputStatus({ value: data.data.status, label: data.data.keterangan_status });
            setInputNamaDepartemen(data.data.nama_departemen);

            dataStatus.push(...reSelectStatus.data);

            setIsLoading(false);
            return () => {
                
            };
        }, []);
        
    /* ----------
    Component Render
    ------------------ */
        const renderTabs = () => {
            if(tabs.length > 0) {
                // return <PageTabHeader tabs={tabs} active={activeTab} handleActiveTab={(data) => handleActiveTab(data)} />
            }
        }
    /* ----------
    Method
    ------------------ */
        // function handleActiveTab(data) {
        //     setActiveTab(data)
        // }

        const handleSubmit = async (e) => {
            e.preventDefault();
            setIsLoadingSubmit(true);
            
            const formSubmit = {
                departemen_id: dataID,
                nama_departemen: inputNamaDepartemen,
                status: inputStatus.value,
            }
            let res = await ModelReferensiDepartemen.update(formSubmit);
            // console.log('res', res);
            if(res.status == '100') {
                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("success");

                setTimeout(() => {
                    navigate("/departemen/index/" + APP_HELPERS_COMMON.crypt(pageID.toString()));
                }, 2000);
                
            } else {
                setIsLoadingSubmit(false);
                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("error");
            }
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{pageTitle}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            {
                                                (isLoading) ? 
                                                    <ElementPreloaderForm />
                                                :
                                                    <>
                                                        <div className="row">
                                                            <FormInput type="text" label="Nama Departemen" size="2" mandatory={true} readonly={true} val={inputNamaDepartemen} onChangeValue={(event) => setInputNamaDepartemen(event.target.value)}/>
                                                            <FormInput type="select" label="Status" size="2" mandatory={true} val={inputStatus} option={dataStatus} defaultSelect={inputStatus} onChangeValue={(val) => setInputStatus(val)}/>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12" style={{textAlign: 'left'}}>
                                                                <FormInput type="submit" label="Update" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Edit;