import React from 'react';
import APP_COMMON_HELPER from '../../Helpers/Common';

function PageTableBody(props) {
    function handleActionMenu(e, act, url, status) {
        let action = {
            event: e,
            act,
            url,
            status
        }
        e.preventDefault();
        
        props.handleActionMenu(action);
    }

    return (
        <tbody>
            {
                (props.count > 0) ? 
                    props.data.map(function(item, index) {
                        return  <tr>
                                    {
                                        props.layout.map(function(field, field_index) {
                                            if(field.type == 'text') {
                                                return <td style={{ textAlign: field.align }} key={index + '_' + field_index}>{item[field.field]}</td>
                                            } else {
                                                if(field.type == 'currency') {
                                                    return <td style={{ textAlign: field.align }} key={index + '_' + field_index}>{APP_COMMON_HELPER.formatCurrency(item[field.field])}</td>
                                                } else {
                                                    if(field.type == 'badge') {
                                                        return <td style={{ textAlign: field.align }} key={index + '_' + field_index}><span className={APP_COMMON_HELPER.getBadge(item[field.field])} style={{padding: '7px 12px 7px 12px'}}>{item[field.field]}</span></td>
                                                    } else {
                                                        if(field.type == 'action') {
                                                            return <td style={{ textAlign: field.align }} key={index + '_' + field_index}>
                                                                        <div className="btn-group">
                                                                            <div className="dropdown">
                                                                                <button className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    <i className="bx bx-dots-horizontal-rounded" style={{border:"1px solid #e9e9ee", padding:"5px", borderRadius:"50%"}}></i>
                                                                                </button>
                                                                                <ul className="dropdown-menu dropdown-menu-end">
                                                                                {
                                                                                    field.field.map(function(act_item, act_index){
                                                                                        if(act_item.menu_method != 'direct') {
                                                                                            return <li key={index + '_' + field_index + '_' + act_index}><a className="dropdown-item" href="" onClick={(e) => handleActionMenu(e, act_item.menu_url, act_item.menu_url + "/" + APP_COMMON_HELPER.crypt(item.id.toString()), item.status)}>{act_item.menu_name}</a></li>
                                                                                        }
                                                                                    })
                                                                                }
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                        }   
                                                    }
                                                }
                                            }                                        
                                        })
                                    }
                                </tr>
                    })
                :
                <tr>
                    <td colSpan={props.column.length} style={{textAlign: 'center'}}>Data tidak ada</td>
                </tr>
            }
        </tbody>
    )
}

export default PageTableBody;