import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from 'axios';
import FormInput from '../../../Components/FormInput';
import PageTabHeader from '../../../Components/Page/PageTabHeader';
import PageTitle from '../../../Components/Page/PageTitle';
import APP_MODEL_PENGGAJIAN from '../../../Models/Payroll/penggajian';
import APP_HELPERS_COMMON from '../../../Helpers/Common';
import CommonEndpoint from '../../../Models/common';
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../../Components/Element/ElementPreloaderForm';
import PageTablePaggingSimpleDua from '../../../Components/PageTable/PageTablePaggingSimpleDua';

function Detail() {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
        let urlAction       = useParams();
        let urlID           = urlAction.id;
    /* ----------
    Page Vars
    ------------------ */
        const dataID = APP_HELPERS_COMMON.dcrypt(urlID);
        const [pageTitle, setPageTitle] = useState("Detail Gaji");
        const [pageID, setPageID] = useState(100);
        const [tabs, setTabs] = useState([]);
        const [activeTab, setActiveTab] = useState('');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        const defaultOption = { value: '0', label: ' - Pilih -' };
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        // -------------------
        const [tableData, setTableData] = useState([]);
        const [tableParams, setTableParams] = useState([]);
        const [tablePagging, setTablePagging] = useState([]);
        // -----------------
        const [expandStatus, setExpandStatus] = useState('true');
        const [inputTanggalAwal, setInputTanggalAwal] = useState("");
        const [inputTahun, setInputTahun] = useState(0);
        const [inputBulan, setInputBulan] = useState(0);
        const [dataGaji, setDataGaji] = useState([]);
        const [karyawanId, setKaryawanId] = useState([]);
        const [detailKomponen, setDetailKomponen] = useState([]);
        const [dataPribadi, setDataPribadi] = useState({});
        const [edittableStatus, setEdittableStatus] = useState(0);
        const [isFoundData, setIsFoundData] = useState(false);
        // -----------------
        const [inputCabang, setInputCabang] = useState(defaultOption);
        const [inputKaryawan, setInputKaryawan] = useState(defaultOption);
        const [inputJabatan, setInputJabatan] = useState(defaultOption);
        // -----------------
        const [dataCabang, setDataCabang] = useState([defaultOption]);
        const [dataKaryawan, setDataKaryawan] = useState([defaultOption]);
        const [dataJabatan, setDataJabatan] = useState([defaultOption]);
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let dataSPL = dataID.split("|");

            let search = {
                tanggal_awal: dataSPL[0],
                cabang_id: '0',
                karyawan_id: '0',
                jabatan_id: '0',
            };
            
            let data = await APP_MODEL_PENGGAJIAN.getAllDetail(search);
            let resPenggajian  = await APP_MODEL_PENGGAJIAN.get(data.data.data[0].karyawan_id + '|' + dataSPL[0]);
            
            dataCabang.push(...data.collections.cabang);
            dataKaryawan.push(...data.collections.karyawan);
            dataJabatan.push(...data.collections.jabatan);
            
            setIsFoundData(true);
            setKaryawanId(data.data.data[0].karyawan_id);
            setEdittableStatus(dataSPL[1]);
            setInputTanggalAwal(dataSPL[0]);
            setDataGaji(data.data);
            setDetailKomponen(resPenggajian.data_komponen);
            setDataPribadi(resPenggajian.data_karyawan);
            
            // let prd = inputTanggalAwal.split("-");
            let prd = dataSPL[0].split("-");
            console.log(inputTanggalAwal);
            // console.log(dataSPL)
            setInputTahun(prd[0]);
            setInputBulan(prd[1]);

            refreshTable(data);
            setIsLoading(false);
            
            return () => {
                
            };
        }, []);
    /* ----------
    Component Render
    ------------------ */
        
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }
        
        function handleUpdateKomponen(value, item, index) {
            const updateItem = {
                ...item,
                nominal: value
            }

            const newKomponen = [...detailKomponen];

            newKomponen[index] = updateItem;
            setDetailKomponen(newKomponen);
        }

        async function handleSubmitKomponen(e) {
            e.preventDefault();

            setIsLoadingSubmit(true);

            const formSubmit = {
                tanggal_awal: inputTanggalAwal,
                karyawan_id: karyawanId,
                komponen_gaji: detailKomponen,
            }
            console.log(formSubmit);
            // return false;
            let res = await APP_MODEL_PENGGAJIAN.updateKomponen(formSubmit);

            if(res.status == '100') {
                setIsLoadingSubmit(false);

                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("success");
            } else {
                setIsLoadingSubmit(false);
                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("error");
            }

            setIsLoadingSubmit(false);
        }

        async function handleSubmitSearch(e) {
            e.preventDefault();

            setIsLoadingSubmit(true);

            let search = {
                tanggal_awal: inputTanggalAwal,
                cabang_id: inputCabang.value,
                karyawan_id: inputKaryawan.value,
                jabatan_id: inputJabatan.value,
            };

            let data = await APP_MODEL_PENGGAJIAN.getAllDetail(search);

            if(data.data.data.length > 0) {
                let resPenggajian  = await APP_MODEL_PENGGAJIAN.get(data.data.data[0].karyawan_id + '|' + inputTanggalAwal);
                
                setIsFoundData(true);
                setKaryawanId(data.data.data[0].karyawan_id);
                setDataGaji(data.data);
                setDetailKomponen(resPenggajian.data_komponen);
                setDataPribadi(resPenggajian.data_karyawan);
            } else {
                setIsFoundData(false);
            }
            
            refreshTable(data);
            setIsLoadingSubmit(false);
        }

        async function handleSubmitUnfilter(e) {
            e.preventDefault();

            setIsLoadingSubmit(true);

            setInputCabang(defaultOption);
            setInputKaryawan(defaultOption);
            setInputJabatan(defaultOption);

            let search = {
                tanggal_awal: inputTanggalAwal,
                cabang_id: '0',
                karyawan_id: '0',
                jabatan_id: '0',
            };

            let data = await APP_MODEL_PENGGAJIAN.getAllDetail(search);
            let resPenggajian  = await APP_MODEL_PENGGAJIAN.get(data.data.data[0].karyawan_id + '|' + inputTanggalAwal);
           
            setKaryawanId(data.data.data[0].karyawan_id);
            setDataGaji(data.data);
            setDetailKomponen(resPenggajian.data_komponen);
            setDataPribadi(resPenggajian.data_karyawan);
            
            refreshTable(data);
            setIsLoadingSubmit(false);
        }

        async function handleUrl(url) {
            setIsLoadingSubmit(true);

            let search = {
                tanggal_awal: inputTanggalAwal,
                cabang_id: inputCabang.value,
                karyawan_id: inputKaryawan.value,
                jabatan_id: inputJabatan.value,
            };

            let data = await CommonEndpoint.get_pagging(url, search);
            let resPenggajian  = await APP_MODEL_PENGGAJIAN.get(data.data.data[0].karyawan_id + '|' + inputTanggalAwal);
            
            setKaryawanId(data.data.data[0].karyawan_id);
            setDataGaji(data.data);
            setDetailKomponen(resPenggajian.data_komponen);
            setDataPribadi(resPenggajian.data_karyawan);
            
            refreshTable(data);
            setIsLoadingSubmit(false);
        }

        function refreshTable(data) {
            setTableData(data.data.data);
            setTableParams(data.data);
            setTablePagging(data.data.links);
        }

        const renderFilterButton = () => {
            return <div className="row">
                        <div className="col-md-12" style={{textAlign: 'left'}}>
                            <FormInput type="submit" label="Filter Data" loading={isLoadingSubmit} handleSubmit={handleSubmitSearch} />
                            {/* <FormInput type="submit" style="btn-warning" label="Unfilter" loading={isLoadingSubmit} handleSubmit={handleSubmitUnfilter} /> */}
                        </div>
                    </div>
        }

        const renderSubmit = () => {
            return <div className="row">
                        <div className="col-md-12" style={{textAlign: 'left'}}>
                            <FormInput type="submit" label="Simpan" loading={isLoadingSubmit} handleSubmit={handleSubmitKomponen} />
                        </div>
                    </div>
        }

        const renderPreloader = () => {
            return <div className="row">
                        <div className="col-md-3" style={{marginBottom: 10}}>
                            <div style={{display:"flex"}} className="placeholder-glow">
                                <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                            </div>
                        </div>
                        <div className="col-md-9" style={{marginBottom: 10}}>
                            <div style={{display:"flex"}} className="placeholder-glow">
                                <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                            </div>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 10}}>
                            <div style={{display:"flex"}} className="placeholder-glow">
                                <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                            </div>
                        </div>
                        <div className="col-md-9" style={{marginBottom: 10}}>
                            <div style={{display:"flex"}} className="placeholder-glow">
                                <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                            </div>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 10}}>
                            <div style={{display:"flex"}} className="placeholder-glow">
                                <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                            </div>
                        </div>
                        <div className="col-md-9" style={{marginBottom: 10}}>
                            <div style={{display:"flex"}} className="placeholder-glow">
                                <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                            </div>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 10}}>
                            <div style={{display:"flex"}} className="placeholder-glow">
                                <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                            </div>
                        </div>
                        <div className="col-md-9" style={{marginBottom: 10}}>
                            <div style={{display:"flex"}} className="placeholder-glow">
                                <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                            </div>
                        </div>
                    </div>
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">                                
                                {
                                    (isLoading) ? 
                                        <ElementPreloaderForm />
                                    :
                                        <>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="accordion" id="accordionFilter" style={{marginBottom: 10}}>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header" id="headingFilter">
                                                                        <button className="accordion-button fw-medium collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFilter" aria-expanded="false" aria-controls="collapseFilter">
                                                                        Filter Data
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFilter" className="accordion-collapse show" aria-labelledby="headingFilter" data-bs-parent="#accordionFilter">
                                                                        <div className="accordion-body">
                                                                            <dl className="row mb-0">
                                                                                <dt className="col-sm-3">Periode Gaji</dt><dd className="col-sm-9">{ inputBulan } - { inputTahun }</dd>
                                                                                <hr />
                                                                                <FormInput type="select" label="Nama Karyawan" size="1" mandatory={true} val={inputKaryawan} option={dataKaryawan} defaultSelect={inputKaryawan} onChangeValue={(val) => setInputKaryawan(val)}/>
                                                                                <FormInput type="select" label="Kantor Cabang" size="1" mandatory={true} val={inputCabang} option={dataCabang} defaultSelect={inputCabang} onChangeValue={(event) => setInputCabang(event)}/>
                                                                                <FormInput type="select" label="Jabatan" size="1" mandatory={true} val={inputJabatan} option={dataJabatan} defaultSelect={inputJabatan} onChangeValue={(event) => setInputJabatan(event)}/>
                                                                                {renderFilterButton()}
                                                                            </dl>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="accordion" id="accordionExample" style={{marginBottom: 10}}>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header" id="headingOne">
                                                                        <button className="accordion-button fw-medium collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                        Data Pribadi
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseOne" className="accordion-collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            {
                                                                                (isLoadingSubmit) ? 
                                                                                    renderPreloader()
                                                                                : 
                                                                                    (isFoundData) ? 
                                                                                        <dl className="row mb-0">
                                                                                            <dt className="col-sm-3">No Identitas</dt><dd className="col-sm-9">{ dataPribadi.no_identitas } (Exp { dataPribadi.tanggal_berlaku_identitas })</dd>
                                                                                            <dt className="col-sm-3">Nama Lengkap</dt><dd className="col-sm-9">{ dataPribadi.nama_karyawan }</dd>
                                                                                            <dt className="col-sm-3">Tempat, Tgl Lahir</dt><dd className="col-sm-9">{ dataPribadi.tempat_lahir }, { dataPribadi.tanggal_lahir }</dd>
                                                                                            <dt className="col-sm-3">Alamat</dt><dd className="col-sm-9">{ dataPribadi.alamat }</dd>
                                                                                            <dt className="col-sm-3">Kode Pos</dt><dd className="col-sm-9">{ dataPribadi.kode_pos }</dd>
                                                                                            <dt className="col-sm-3">Jenis Kelamin</dt><dd className="col-sm-9">{ dataPribadi.ket_jenis_kelamin }</dd>
                                                                                            <dt className="col-sm-3">Status Kawin</dt><dd className="col-sm-9">{ dataPribadi.ket_status_nikah }</dd>
                                                                                            <dt className="col-sm-3">Tgl Akhir Identitas</dt><dd className="col-sm-9">{ dataPribadi.tanggal_berlaku_identitas }</dd>
                                                                                            <dt className="col-sm-3">Gol Darah</dt><dd className="col-sm-9">{ dataPribadi.ket_golongan_darah }</dd>
                                                                                            <dt className="col-sm-3">Agama</dt><dd className="col-sm-9">{ dataPribadi.ket_agama }</dd>
                                                                                            <dt className="col-sm-3">Email</dt><dd className="col-sm-9">{ dataPribadi.email }</dd>
                                                                                            <dt className="col-sm-3">No HP</dt><dd className="col-sm-9">{ dataPribadi.no_hp }</dd>
                                                                                            <dt className="col-sm-3">No Telp</dt><dd className="col-sm-9">{ dataPribadi.no_telp }</dd>
                                                                                        </dl>
                                                                                    : <p>Data tidak ditemukan</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header" id="headingTwo">
                                                                        <button className="accordion-button fw-medium collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                        Data Karyawan
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            {
                                                                                (isLoadingSubmit) ? 
                                                                                    renderPreloader()
                                                                                : 
                                                                                    (isFoundData) ? 
                                                                                        <dl className="row mb-0">
                                                                                            <dt className="col-sm-3">N I K</dt><dd className="col-sm-9">{ dataPribadi.nik }</dd>
                                                                                            <dt className="col-sm-3">Tgl Masuk</dt><dd className="col-sm-9">{ dataPribadi.tanggal_masuk }</dd>
                                                                                            <dt className="col-sm-3">Tgl Resign</dt><dd className="col-sm-9">{ dataPribadi.tanggal_berakhir }</dd>
                                                                                            <dt className="col-sm-3">Departemen</dt><dd className="col-sm-9">{ dataPribadi.nama_departemen }</dd>
                                                                                            <dt className="col-sm-3">Jabatan</dt><dd className="col-sm-9">{ dataPribadi.nama_jabatan }</dd>
                                                                                            <dt className="col-sm-3">Level</dt><dd className="col-sm-9">{ dataPribadi.ket_level }</dd>
                                                                                            <dt className="col-sm-3">Kantor Cabang</dt><dd className="col-sm-9">{ dataPribadi.nama_cabang }</dd>
                                                                                            <dt className="col-sm-3">Status Karyawan</dt><dd className="col-sm-9">{ dataPribadi.ket_status_karyawan }</dd>
                                                                                        </dl>
                                                                                    : <p>Data tidak ditemukan</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header" id="headingThree">
                                                                        <button className="accordion-button fw-medium collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                        Data Penggajian
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            {
                                                                                (isLoadingSubmit) ? 
                                                                                    renderPreloader()
                                                                                : 
                                                                                    (isFoundData) ? 
                                                                                        <dl className="row mb-0">
                                                                                            <dt className="col-sm-3">No Rekening</dt><dd className="col-sm-9">{ dataPribadi.no_rekening_bank }</dd>
                                                                                            <dt className="col-sm-3">Bank Rekening</dt><dd className="col-sm-9">{ dataPribadi.nama_bank }</dd>
                                                                                            <dt className="col-sm-3">Rekening A/N</dt><dd className="col-sm-9">{ dataPribadi.atas_nama_rekening }</dd>
                                                                                            <hr style={{marginTop: 10}} />
                                                                                            <dt className="col-sm-3">No BPJS Kes</dt><dd className="col-sm-9">{ dataPribadi.no_bpjs_kesehatan }</dd>
                                                                                            <dt className="col-sm-3">Tgl BPJS Kes</dt><dd className="col-sm-9">{ dataPribadi.tanggal_bpjs_kesehatan }</dd>
                                                                                            <dt className="col-sm-3">Status BPJS Kes</dt><dd className="col-sm-9">{ (dataPribadi.status_pembayaran_bpjs === 1) ? 'Dibayarkan' : 'Tidak Dibayarkan' }</dd>
                                                                                            <hr style={{marginTop: 10}} />
                                                                                            <dt className="col-sm-3">No BPJS Naker</dt><dd className="col-sm-9">{ dataPribadi.no_bpjs_ketenagakerjaan }</dd>
                                                                                            <dt className="col-sm-3">Tgl BPJS Naker</dt><dd className="col-sm-9">{ dataPribadi.tanggal_bpjs_ketenagakerjaan }</dd>
                                                                                            <hr style={{marginTop: 10}} />
                                                                                            <dt className="col-sm-3">No NPWP</dt><dd className="col-sm-9">{ dataPribadi.no_npwp }</dd>
                                                                                            <dt className="col-sm-3">Status PTKP</dt><dd className="col-sm-9">{ dataPribadi.ket_status_ptkp }</dd>
                                                                                            <dt className="col-sm-3">Status JHT</dt><dd className="col-sm-9">{ (dataPribadi.status_pembayaran_jht === 1) ? 'Dibayarkan' : 'Tidak Dibayarkan' }</dd>
                                                                                            <dt className="col-sm-3">Status Pensiun</dt><dd className="col-sm-9">{ (dataPribadi.status_pembayaran_pensiun === 1) ? 'Dibayarkan' : 'Tidak Dibayarkan' }</dd>
                                                                                        </dl>
                                                                                    : <p>Data tidak ditemukan</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            {
                                                                (edittableStatus === '1') ?
                                                                        (isFoundData) ? renderSubmit() : null
                                                                : null
                                                            }
                                                        </div>
                                                        <div className="col-md-6">
                                                            <br />
                                                            <PageTablePaggingSimpleDua params={tableParams} count={tableData.length} pagging={tablePagging} handleUrl={(url) => handleUrl(url)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="accordion" id="accordionExample1">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingThree1">
                                                                <button className="accordion-button fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree1"  aria-expanded={expandStatus} aria-controls="collapseThree1">
                                                                Data Gaji
                                                                </button>
                                                            </h2>
                                                            <div id="collapseThree1" className="accordion-collapse collapse show" aria-labelledby="headingThree1" data-bs-parent="#accordionExample1">
                                                                <div className="accordion-body">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-hover mb-0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{width: '10%', textAlign: 'center'}}>Kode</th>
                                                                                    <th style={{ textAlign: 'center' }}>Nama</th>
                                                                                    <th style={{width: '30%', textAlign: 'center'}}>Nominal</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    (isLoadingSubmit) ? 
                                                                                        <>
                                                                                            <tr>
                                                                                                <td style={{width: '10%', textAlign: 'center'}}>
                                                                                                    <div style={{display:"flex"}} className="placeholder-glow">
                                                                                                        <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td style={{ textAlign: 'center' }}>
                                                                                                    <div style={{display:"flex"}} className="placeholder-glow">
                                                                                                        <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td style={{width: '30%', textAlign: 'center'}}>
                                                                                                    <div style={{display:"flex"}} className="placeholder-glow">
                                                                                                        <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td style={{width: '10%', textAlign: 'center'}}>
                                                                                                    <div style={{display:"flex"}} className="placeholder-glow">
                                                                                                        <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td style={{ textAlign: 'center' }}>
                                                                                                    <div style={{display:"flex"}} className="placeholder-glow">
                                                                                                        <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td style={{width: '30%', textAlign: 'center'}}>
                                                                                                    <div style={{display:"flex"}} className="placeholder-glow">
                                                                                                        <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td style={{width: '10%', textAlign: 'center'}}>
                                                                                                    <div style={{display:"flex"}} className="placeholder-glow">
                                                                                                        <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td style={{ textAlign: 'center' }}>
                                                                                                    <div style={{display:"flex"}} className="placeholder-glow">
                                                                                                        <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td style={{width: '30%', textAlign: 'center'}}>
                                                                                                    <div style={{display:"flex"}} className="placeholder-glow">
                                                                                                        <span style={{height: 30, width: "100%", borderRadius: 5}} className="placeholder"></span>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </>
                                                                                    : 
                                                                                        (isFoundData) ? 
                                                                                            detailKomponen.map(function(item, index) {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <th scope="row" style={{ textAlign: 'center', backgroundColor: (item.jenis_komponen !== 'MANUAL') ? '#ffe5ba' : 'white' }}>{ item.kode_komponen }</th>
                                                                                                            <td style={{ backgroundColor: (item.jenis_komponen !== 'MANUAL') ? '#ffe5ba' : 'white' }}>{ item.nama_komponen } <br /> <span style={{ fontSize: 10, fontStyle: 'italic', color: 'red' }}>{ item.deskripsi_komponen }</span></td>
                                                                                                            
                                                                                                                {
                                                                                                                    (edittableStatus === '1')  ?
                                                                                                                        <td style={{ backgroundColor: (item.jenis_komponen !== 'MANUAL') ? '#ffe5ba' : 'white' }}>
                                                                                                                            <FormInput type="currency-only" label={item.nama_komponen} size="2" mandatory={true} val={item.nominal} readonly={((item.jenis_komponen !== 'MANUAL') || ((item.kode_komponen === 'GJJ') || (item.kode_komponen === 'GJ') || (item.kode_komponen === 'DUB') || (item.kode_komponen === 'DUP') || (item.kode_komponen === 'RATE_GH') || (item.kode_komponen === 'RATE_UM'))) ? 'readonly' : ''} onChangeValue={(value) => handleUpdateKomponen(value, item, index)} />
                                                                                                                        </td>
                                                                                                                    : 
                                                                                                                        <td style={{ backgroundColor: (item.jenis_komponen !== 'MANUAL') ? '#ffe5ba' : 'white', textAlign: 'right' }}>
                                                                                                                            { APP_HELPERS_COMMON.formatCurrency(item.nominal) }
                                                                                                                        </td>
                                                                                                                }
                                                                                                        </tr>
                                                                                                    </>
                                                                                                )
                                                                                            })
                                                                                        :
                                                                                            <tr>
                                                                                                <td colSpan="3">Data tidak ditemukan</td>
                                                                                            </tr>
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div>  
                                            </div>  
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Detail;